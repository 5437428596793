<template>
  <div>
    <sidebar>
      <!-- =================== Property Analysis =================== -->
      <!-- <div class="lineTop mt-2"></div>
      <sidebar-dropdown label="Analysis" iconClass="fas fa-chart-bar">
        <sidebar-item
          label="Property Analysis"
          :to="{ name: 'PropertyAnalysis' }"
        ></sidebar-item>
        <sidebar-item
          label="Financial Analysis"
          :to="{ name: 'FinancialAnalysis' }"
        ></sidebar-item>
        <sidebar-item
          label="Buyer Analysis"
          :to="{ name: 'BuyerAnalysis' }"
        ></sidebar-item>
      </sidebar-dropdown> -->
      <!-- =================== Agent Performance =================== -->
      <!-- <sidebar-item
        label="Agent Performance"
        iconClass="fas fa-tachometer-alt"
        :to="{ name: 'AgentPerformance' }"
      ></sidebar-item> -->

      <sidebar-separator label="Users"></sidebar-separator>
      <!-- ===================== Manage Buyer ====================== -->
      <sidebar-item
        iconClass="fas fa-user-tag"
        label="Manage Buyer"
        :to="{ name: 'ManageBuyer' }"
      ></sidebar-item>
      <!-- ======================= Manage Designers ======================= -->
      <sidebar-dropdown label="Designer" iconClass="fas fa-magic">
        <sidebar-item
          label="Manage Designer"
          :to="{ name: 'ManageDesigners' }"
        ></sidebar-item>
        <sidebar-item
          label="Add Designer"
          :to="{ name: 'ManageDesignersAdd' }"
        ></sidebar-item>
      </sidebar-dropdown>
      <!-- ======================= Manage Developers ======================= -->
      <sidebar-dropdown label="Developer" iconClass="fas fa-hard-hat">
        <sidebar-item
          label="Manage Developer"
          :to="{ name: 'ManageDevelopers' }"
        ></sidebar-item>
        <sidebar-item
          label="Add Developer"
          :to="{ name: 'ManageDevelopersAdd' }"
        ></sidebar-item>
      </sidebar-dropdown>

      <!-- ======================= Manage Banker ======================= -->
      <sidebar-dropdown label="Banker" iconClass="fas fa-donate">
        <sidebar-item
          label="Manage Banker"
          :to="{ name: 'ManageBankers' }"
        ></sidebar-item>
        <sidebar-item
          label="Add Banker"
          :to="{ name: 'ManageBankersAdd' }"
        ></sidebar-item>
      </sidebar-dropdown>
      <!-- ======================= Manage Lawyer ======================= -->
      <sidebar-dropdown label="Lawyer" iconClass="fas fa-gavel">
        <sidebar-item
          label="Manage Lawyer"
          :to="{ name: 'ManageLawyers' }"
        ></sidebar-item>
        <sidebar-item
          label="Add Lawyer"
          :to="{ name: 'ManageLawyersAdd' }"
        ></sidebar-item>
      </sidebar-dropdown>

      <!-- ===================== Manage Agency ===================== -->
      <sidebar-separator label="Agency"></sidebar-separator>
      <sidebar-dropdown label="Agency" iconClass="fas fa-briefcase">
        <sidebar-item
          label="Manage Agency"
          :to="{ name: 'ManageAgency' }"
        ></sidebar-item>
        <sidebar-item
          label="Add Agency"
          :to="{ name: 'ManageAgencyAdd' }"
        ></sidebar-item>
      </sidebar-dropdown>
      <!-- ===================== Manage Branch ===================== -->
      <sidebar-dropdown label="Branch" iconClass="fas fa-code-branch">
        <sidebar-item
          label="Manage Branch"
          :to="{ name: 'ManageBranch' }"
        ></sidebar-item>
        <sidebar-item
          label="Add Branch"
          :to="{ name: 'ManageBranchAdd' }"
        ></sidebar-item>
      </sidebar-dropdown>
      <!-- ===================== Manage Agent ===================== -->
      <sidebar-dropdown label="Agency User" iconClass="fas fa-user-tie">
        <sidebar-item
          label="Manage Agency Users"
          :to="{ name: 'ManageAgencyUsers' }"
        ></sidebar-item>
        <sidebar-item
          label="Add Agency Users"
          :to="{ name: 'ManageAgencyUsersAdd' }"
        ></sidebar-item>
      </sidebar-dropdown>
      <!-- ==================== Manage Property ==================== -->
      <sidebar-item
        iconClass="fas fa-building"
        label="Manage Property"
        :to="{ name: 'ManageProperty' }"
      ></sidebar-item>
      <!-- <sidebar-dropdown label="Property" iconClass="fas fa-building">
        <sidebar-item
          label="Manage Property"
          :to="{ name: 'ManageProperty' }"
        ></sidebar-item>
        <sidebar-item
          label="Add Property"
          :to="{ name: 'ManagePropertyAdd' }"
        ></sidebar-item>
      </sidebar-dropdown> -->
      <!-- ===================== Manage Appointment ===================== -->
      <!-- <sidebar-dropdown label="Appointment" iconClass="fas fa-calendar-check">
        <sidebar-item
          label="Manage Appointment"
          :to="{ name: 'Appointment' }"
        ></sidebar-item>
        <sidebar-item
          label="Add Appointment"
          :to="{ name: 'AppointmentAdd' }"
        ></sidebar-item>
      </sidebar-dropdown> -->

      <sidebar-separator label="System"></sidebar-separator>
      <!-- ==================== Manage Admin Roles ==================== -->
      <!-- <sidebar-item
        iconClass="fas fa-user-tag"
        label="Manage Admin Roles"
        :to="{ name: 'ManageCountries' }"
      ></sidebar-item> -->
      <!-- ==================== Manage Admin Roles ==================== -->
      <sidebar-dropdown label="App Banner & Popup" iconClass="fas fa-images">
        <sidebar-item label="Manage Banners" :to="{ name: 'ManageAppBanners' }">
        </sidebar-item>
        <sidebar-item label="Manage Popups" :to="{ name: 'ManageAppPopups' }">
        </sidebar-item>
      </sidebar-dropdown>
      <!-- ===================== Manage Addresses ===================== -->
      <sidebar-item
        label="Loan Application Form"
        :to="{ name: 'LoanApplicationFormBuilder' }"
        iconClass="fas fa-file-alt"
      ></sidebar-item>

      <!-- ===================== Manage Addresses ===================== -->
      <sidebar-dropdown
        label="Manage Addresses"
        iconClass="fas fa-map-marked-alt"
      >
        <sidebar-item
          label="Manage Countries"
          :to="{ name: 'ManageCountries' }"
        ></sidebar-item>
        <sidebar-item
          label="Manage States"
          :to="{ name: 'ManageStates' }"
        ></sidebar-item>
        <sidebar-item
          label="Manage Cities"
          :to="{ name: 'ManageCities' }"
        ></sidebar-item>
        <sidebar-item
          label="Manage Areas"
          :to="{ name: 'ManageAreas' }"
        ></sidebar-item>
        <sidebar-item
          label="Manage Buildings"
          :to="{ name: 'ManageBuildings' }"
        ></sidebar-item>
      </sidebar-dropdown>
      <!-- ================ Manage Facilities and Amenities ================ -->
      <sidebar-dropdown
        label="Facilities & Amenities"
        iconClass="fas fa-dumbbell"
      >
        <sidebar-item
          label="Manage Facilities"
          :to="{ name: 'ManageFacilities' }"
        ></sidebar-item>
        <sidebar-item
          label="Manage Amenities"
          :to="{ name: 'ManageAmenities' }"
        ></sidebar-item>
      </sidebar-dropdown>
      <!-- ==================== Manage Property Type ==================== -->
      <sidebar-dropdown
        label="Property & Lot Type"
        iconClass="fas fa-door-closed"
      >
        <sidebar-item
          label="Manage Property Types"
          :to="{ name: 'ManagePropertyTypes' }"
        ></sidebar-item>
        <sidebar-item
          label="Manage Lot Types"
          :to="{ name: 'ManageLotTypes' }"
        ></sidebar-item>
      </sidebar-dropdown>
      <!-- ==================== Manage Property Tags ===================== -->
      <sidebar-item
        label="Manage Tags"
        iconClass="fas fa-hashtag"
        :to="{ name: 'ManagePropertyTags' }"
      ></sidebar-item>
      <!-- =============== Manage Property Custom Categories =============== -->
      <sidebar-item
        label="Property Custom Categories"
        iconClass="fas fa-clipboard-list"
        :to="{ name: 'ManagePropertyCustomCategories' }"
      ></sidebar-item>

      <div class="lineTop my-2"></div>

      <!-- ==================== Manage Account ===================== -->
      <sidebar-item
        label="Manage Account"
        iconClass="fas fa-user-circle"
        :to="{ name: 'ManageAccount' }"
      ></sidebar-item>
    </sidebar>
  </div>
</template>

<script>
export default {
  components: {
    Sidebar: () =>
      import(
        "@/components/GlobalComponents/FdLayout/SidebarComponents/Sidebar"
      ),
    SidebarItem: () =>
      import(
        "@/components/GlobalComponents/FdLayout/SidebarComponents/SidebarItem"
      ),
    SidebarDropdown: () =>
      import(
        "@/components/GlobalComponents/FdLayout/SidebarComponents/SidebarDropdown"
      ),
    SidebarSeparator: () =>
      import(
        "@/components/GlobalComponents/FdLayout/SidebarComponents/SidebarSeparator.vue"
      )
  },
  mixins: [],
  props: {},
  data: function () {
    return {
      permission: {}
    };
  },
  computed: {
    isMenuOpen() {
      return this.$store.getters.getSidebarState;
    }
  },
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {
    // this.permission = JSON.parse(localStorage.getItem("permission"));
  },
  methods: {
    async logout() {
      let c = await this.$confirm({
        title: "Logout",
        message: "Are you sure to logout?",
        confirmText: "Logout"
      });

      if (c) {
        this.$auth
          .logout({
            makeRequest: false,
            redirect: { path: "/login" }
          })
          .catch((err) => {
            throw err;
          });
      }
    }
  }
};
</script>
